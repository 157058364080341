import React from 'react'
import './Courses.scss'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import 'select2'
import { debounce } from '../../utils/debounce'
import { getKey } from '../../utils/key'
import { isMobileOnly } from 'react-device-detect'

import Navbar from '../shared/Navbar/Navbar'
import Pages from '../shared/Pages/Pages'
import Empty from '../shared/Empty/Empty'
import Subscribe from '../shared/Subscribe/Subscribe'
import Footer from '../shared/Footer/Footer'
import placeholderImage from '../../assets/course-plug.svg'
import { ReactComponent as FilterBars } from '../../assets/filter-bars.svg'
import { ReactComponent as Error } from '../../assets/error.svg'
import { ReactComponent as Cancel } from '../../assets/cancel.svg'

import bootstrapService from '../../services/BootstrapService'

class Courses extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.filterMenu = null;
        this.mobileFilterMenu = null;
        this.mobileSubmitButton = null;
        this.coursesList = null;

        // State
        this.state = {
            categoryFilter: null,
            searchFilter: null,
            sortFilter: null,
            pageNumber: 0,
        }

        // This Binding
        this.openMobileFilterMenu = this.openMobileFilterMenu.bind(this);
        this.closeMobileFilterMenu = this.closeMobileFilterMenu.bind(this);
        this.clickMobileFilterItem = this.clickMobileFilterItem.bind(this);
        this.clickMobileSubmit = this.clickMobileSubmit.bind(this);
        this.changeCoursesPage = this.changeCoursesPage.bind(this);
        this.searchCourses = debounce(this.searchCourses.bind(this), 400);
        this.searchCoursesMobile = debounce(this.searchCoursesMobile.bind(this), 400);
    }

    // Lifecyle
    componentDidMount() {
        window.scrollTo(0, 0);
        this.setupSelects();
    }

    componentDidUpdate() {
        this.updateSelects();
    }

    // Helpers
    setupSelects() {
        $('.a4-select select').select2({
            minimumResultsForSearch: -1,
        });

        $('.a4-select select').on("change", (e, shouldIgnore) => {
            if (!shouldIgnore) {
                const { filterType } = e.currentTarget.dataset;
                this.setMobileFilterActive(filterType, e.currentTarget.value);

                if (filterType === "category") {
                    this.filterCourses(e.currentTarget.value)
                }
                else if (filterType === "sort") {
                    this.sortCourses(e.currentTarget.value)
                }
            }
        });
    }

    updateSelects() {
        if (!$('.a4-select select').first().hasClass("select2-hidden-accessible")) {
            this.setupSelects();
        }
    }

    setMobileFilterActive(filterType, filterValue) {
        const filter = $(this.mobileFilterMenu).find(`.a4-mobile-filter-items-item[data-filter-type='${filterType}'][data-value='${filterValue}']`);
        filter.addClass("-active");
        filter.siblings(".-active").removeClass("-active");

        const select = $(this.filterMenu).find(`select[data-filter-type='${filterType}']`);
        select.val(filter.data().value);
        select.trigger("change", true);
    }

    filterCourses(filterOption) {
        this.enableMobileSpinner();
        this.setState({
            categoryFilter: filterOption !== "" ? filterOption : null,
            pageNumber: 0,
        }, this.disableMobileSpinner())
    }

    sortCourses(sortOption) {
        this.enableMobileSpinner();
        this.setState({
            sortFilter: sortOption,
            pageNumber: 0,
        }, this.disableMobileSpinner())
    }

    enableMobileSpinner() {
        this.mobileSubmitButton.setAttribute("disabled", true);
        this.mobileSubmitButton.classList.add("-loading");
    }

    disableMobileSpinner() {
        if (this.mobileSubmitButton) {
            this.mobileSubmitButton.removeAttribute("disabled");
            this.mobileSubmitButton.classList.remove("-loading");
        }
    }

    // Event Handlers
    openMobileFilterMenu() {
        document.body.classList.add("overflow-hidden");
        this.mobileFilterMenu.classList.add("-open");
        this.disableMobileSpinner();
    }

    closeMobileFilterMenu() {
        document.body.classList.remove("overflow-hidden");
        this.mobileFilterMenu.classList.remove("-open");
    }

    clickMobileFilterItem(e) {
        this.enableMobileSpinner();

        const { filterType, value } = e.currentTarget.dataset;
        this.setMobileFilterActive(filterType, value);

        setTimeout((filterType, value) => {
            if (filterType === "category") {
                this.filterCourses(value);
            }
            else if (filterType === "sort") {
                this.sortCourses(value);
            }
        }, 1000, filterType, value);
    }

    clickMobileSubmit() {
        this.closeMobileFilterMenu();
    }

    searchCourses(e) {
        $(this.mobileFilterMenu).find(".a4-input").find("input").val(e.target.value)
        this.setState({
            searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null,
            pageNumber: 0,
        });
    }

    searchCoursesMobile(e) {
        this.enableMobileSpinner();
        $(this.filterMenu).find(".a4-input").find("input").val(e.target.value)

        setTimeout(() => {
            this.setState({
                searchFilter: e.target.value !== "" ? e.target.value.toLowerCase() : null,
                pageNumber: 0,
            }, this.disableMobileSpinner());
        }, 600);
    }

    changeCoursesPage(e, page) {
        window.scrollTo(0, 0);
        this.setState({ pageNumber: page - 1 });
    }

    // Data
    getCourses() {
        const allCourses = bootstrapService.data ? bootstrapService.data.courseCatalog.courses : null;
        let courses = allCourses;

        if (courses && this.state.categoryFilter) {
            courses = courses.filter(c => c.subjectArea.replace(/\s+/g, "-").trim().toLowerCase() === this.state.categoryFilter)
        }

        if (courses && this.state.searchFilter) {
            courses = courses.filter(c => c.title.toLowerCase().search(this.state.searchFilter) > -1)
        }

        if (courses && this.state.sortFilter) {
            if (this.state.sortFilter === "default") {
                courses = courses.sort(function (a, b) {
                    //sort by course.order (first)
                    let aval = typeof a.order !== 'undefined' ? a.order : null;
                    let bval = typeof b.order !== 'undefined' ? b.order : null;
                    if (aval === bval) {
                        //sort by course.title (second, when order is same)
                        if (a.title === b.title) {
                            return 0;
                        }
                        return a.title < b.title ? -1 : 1;
                    }
                    return aval > bval ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === "title") {
                courses = courses.sort(function (a, b) {
                    if (a.title === b.title) {
                        return 0;
                    }
                    return a.title < b.title ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === "credits") {
                courses = courses.sort(function (a, b) {
                    //sort by course.credits (first)
                    if (a.credits === b.credits) {
                        //sort by course.title (second, when order is same)
                        if (a.title === b.title) {
                            return 0;
                        }
                        return a.title < b.title ? -1 : 1;
                    }
                    return a.credits < b.credits ? -1 : 1;
                })
            }
            else if (this.state.sortFilter === "release-date") {
                courses = courses.sort(function (a, b) {
                    if (a.releaseDate === b.releaseDate) {
                        return 0;
                    }
                    return a.releaseDate > b.releaseDate ? -1 : 1;
                })
            }
            else {
                console.log("INVALID SORT OPTION")
            }
        }

        return courses
    }

    // Component
    render() {
        const coursesData = bootstrapService.data.landing.pages.find(p => p.name === "course-catalog");

        const header = coursesData ? coursesData.header : null;
        const categoryFilters = coursesData ? coursesData.subjectAreaFilters : null;
        const sortOptions = coursesData ? coursesData.sortOptions : null;

        const courses = this.getCourses();
        const pagedCourses = courses ? courses.slice(10 * this.state.pageNumber, 10 * this.state.pageNumber + 10) : null;

        const renderKey = getKey();

        const showReleaseDate = bootstrapService.getShowReleaseDate();

        return (
            <>
                {
                    coursesData &&
                    <>
                        <Navbar backgroundColor="-primary-light" activeLink="courses" />
                        <div className="a4-view">
                            <div className="a4-layout a4-course-catalog">
                                <section className="a4-course-catalog-title">
                                    <div className="a4-course-catalog-container" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-delay="50" data-aos-anchor-placement="top-bottom">
                                        <h1>{header}</h1>
                                    </div>
                                </section>
                                <section className="a4-course-catalog-filter-panel">
                                    <div className="a4-course-catalog-filter-panel-container">
                                        <div className="a4-course-catalog-filter-panel-content" data-aos={isMobileOnly ? undefined : "fade-up"} ref={div => this.filterMenu = div}>
                                            <div className="a4-course-catalog-filter-panel-section-1 md_hidden">
                                                <button className="a4-btn" id="filter-menu-btn" onClick={this.openMobileFilterMenu}>
                                                    <i>
                                                        <svg className="a4-svgsprite">
                                                            <FilterBars stroke={bootstrapService.secondaryColor} />
                                                        </svg>
                                                    </i>
                                                    <span>
                                                        Browse
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="a4-course-catalog-filter-panel-section-2">
                                                {
                                                    categoryFilters && categoryFilters.length > 0 &&
                                                    <div className="filter-item" id="category-filter">
                                                        <div className="a4-select">
                                                            <select data-filter-type="category">
                                                                <option value=''>All Categories</option>
                                                                {
                                                                    categoryFilters.map((filter, index) => {
                                                                        return <option value={filter.replace(/\s+/g, "-").trim().toLowerCase()} key={index}>{filter}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="a4-input -search filter-item">
                                                    <input required placeholder="Search" onChange={this.searchCourses} />
                                                    <label>Search</label>
                                                    <span className="a4-input-error"></span>
                                                    <svg className="a4-svgsprite -error text-secondary">
                                                        <Error />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className="a4-course-catalog-filter-panel-section-3">
                                                {
                                                    sortOptions && sortOptions.length > 0 &&
                                                    <>
                                                        <div className="a4-course-catalog-filter-panel-section-title">
                                                            <h4>Sort</h4>
                                                        </div>
                                                        <div id="sort-filter">
                                                            <div className="a4-select">
                                                                <select data-filter-type="sort">
                                                                    <option value='default'>Default</option>
                                                                    {
                                                                        sortOptions.map((option, index) => {
                                                                            return <option value={option.replace(/\s+/g, "-").trim().toLowerCase()} key={index}>{option === "Credits" ? bootstrapService.getCreditsTerm(2.0) : option}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className="a4-mobile-filter-menu" ref={div => this.mobileFilterMenu = div}>
                                            <div className="a4-mobile-filter-container">
                                                <div className="a4-mobile-filter-content">
                                                    <div className="a4-mobile-filter-top">
                                                        <div className="a4-mobile-filter-title">
                                                            <h4>Browse</h4>
                                                        </div>
                                                        <div className="a4-mobile-filter-cancel" onClick={this.closeMobileFilterMenu}>
                                                            <svg className="a4-svgsprite -cancel">
                                                                <Cancel />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="a4-mobile-filter-search">
                                                        <div className="a4-input -search filter-item">
                                                            <input required placeholder="Search" onChange={this.searchCoursesMobile} />
                                                            <label>Search</label>
                                                            <span className="a4-input-error"></span>
                                                            <svg className="a4-svgsprite -error text-secondary">
                                                                <Error />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="a4-mobile-filter-list">
                                                        {
                                                            categoryFilters && categoryFilters.length > 0 &&
                                                            <>
                                                                <div className="a4-mobile-filter-list-title">
                                                                    <h4>Category</h4>
                                                                </div>
                                                                <ul className="a4-mobile-filter-items">
                                                                    <li className="a4-mobile-filter-items-item -active" data-filter-type="category" data-value="" key={0} onClick={this.clickMobileFilterItem}>
                                                                        <span>All Categories</span>
                                                                    </li>
                                                                    {
                                                                        categoryFilters && categoryFilters.length > 0 && categoryFilters.map((filter, index) => {
                                                                            return <li className="a4-mobile-filter-items-item" data-filter-type="category" data-value={filter.replace(/\s+/g, "-").trim().toLowerCase()} key={index + 1} onClick={this.clickMobileFilterItem}>
                                                                                <span>{filter}</span>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="a4-mobile-filter-list">
                                                        {
                                                            sortOptions && sortOptions.length > 0 &&
                                                            <>
                                                                <div className="a4-mobile-filter-list-title">
                                                                    <h4>Sort</h4>
                                                                </div>
                                                                <ul className="a4-mobile-filter-items">
                                                                    <li className="a4-mobile-filter-items-item -active" data-filter-type="sort" data-value="default" key={0} onClick={this.clickMobileFilterItem}>
                                                                        <span>Default</span>
                                                                    </li>
                                                                    {
                                                                        sortOptions && sortOptions.length > 0 && sortOptions.map((option, index) => {
                                                                            return <li className="a4-mobile-filter-items-item" data-filter-type="sort" data-value={option.replace(/\s+/g, "-").trim().toLowerCase()} key={index + 1} onClick={this.clickMobileFilterItem}>
                                                                                <span>{option === "Credits" ? bootstrapService.getCreditsTerm(2.0) : option}</span>
                                                                            </li>
                                                                        })
                                                                    }
                                                                </ul>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="a4-mobile-filter-control">
                                                    <button className="a4-btn -primary" ref={button => this.mobileSubmitButton = button} onClick={this.clickMobileSubmit}>
                                                        Show &nbsp;
                                                        <span className="a4-mobile-filter-control-course-count">{courses ? courses.length : 0} courses</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="a4-course-catalog-list" key={renderKey}>
                                    <div className="a4-course-catalog-list-container">
                                        <div className="a4-course-catalog-list-content">
                                            <ul className="a4-course-catalog-list-items" ref={ul => this.coursesList = ul}>
                                                {
                                                    pagedCourses &&
                                                    pagedCourses.map((course, index) => {
                                                        const description = course.description ? course.description.substring(0, 220).split(" ").slice(0, -1).join(" ") + "..." : "...";

                                                        return <li key={index}>
                                                            <Link to={`/course/${course.id}`} className="a4-course-catalog-list-items-item">
                                                                {
                                                                    course.imageUrl &&
                                                                    <div className="a4-course-catalog-list-items-item-image" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-offset="-1">
                                                                        <img src={course.imageUrl ? course.imageUrl : placeholderImage} alt="" />
                                                                    </div>
                                                                }
                                                                <div className="a4-course-catalog-list-items-item-details">
                                                                    <div className="a4-course-catalog-list-items-item-title" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-offset="-1">
                                                                        <h4>{course.title}</h4>
                                                                    </div>
                                                                    <div className="a4-course-catalog-list-items-item-info" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-offset="-1">
                                                                        <div className="a4-course-catalog-list-items-item-info-item">
                                                                            <div className="a4-course-catalog-list-items-item-info-label">{bootstrapService.getCreditsTerm(course.credits)}:</div>
                                                                            <span>{course.credits.toFixed(1)}</span>
                                                                        </div>
                                                                        <div className="a4-course-catalog-list-items-item-info-item -right">
                                                                        {
                                                                            !course.schedules && showReleaseDate && course.releaseDate &&
                                                                            <>
                                                                                <div className="a4-course-catalog-list-items-item-info-label">Publish Date:</div>
                                                                                <span>{new Date(course.releaseDate).toLocaleDateString("en-US", {month: 'short', year: 'numeric'})}</span>
                                                                            </>
                                                                        }
                                                                        {
                                                                            !showReleaseDate &&
                                                                            <>
                                                                                <div className="a4-course-catalog-list-items-item-info-label">Delivery Method:</div>
                                                                                <span>{course.deliveryMethod}</span>
                                                                            </>
                                                                        }
                                                                        </div>
                                                                        <div className="a4-course-catalog-list-items-item-info-item -right">
                                                                        {
                                                                            course.schedules && course.schedules[0] &&
                                                                            <>
                                                                                <div className="a4-course-catalog-list-items-item-info-label">Next Session:</div>
                                                                                <span>{new Date(course.schedules[0].startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}</span>
                                                                            </>
                                                                            
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                    <div className="a4-course-catalog-list-items-item-container" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-offset="-1">
                                                                        <div className="a4-course-catalog-list-items-item-description">
                                                                            {description}
                                                                        </div>
                                                                        <div className="a4-course-catalog-list-items-item-control">
                                                                            <span>Learn More</span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                            {
                                                courses && courses.length > 10 &&
                                                <Pages count={Math.ceil(courses.length / 10)} page={this.state.pageNumber + 1} size="large" onChange={this.changeCoursesPage} />
                                            }
                                            {
                                                (this.state.categoryFilter || this.state.searchFilter) && courses.length === 0 &&
                                                <Empty name="courses"/>
                                            }
                                        </div>
                                    </div>
                                </section>
                                <Subscribe />
                                <Footer />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default Courses;