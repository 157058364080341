import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'
import RadioButton from '../../shared/RadioButton/RadioButton'

import bootstrapService from '../../../services/BootstrapService'
import paymentService from '../../../services/PaymentService'
import scheduleService from '../../../services/ScheduleService'

class Webinar extends React.Component {
    constructor(props) {
        super(props);

        // State
        this.state = {
            schedule: this.props.data.schedules[0]
        }

        // This Binding
        this.goToBuy = this.goToBuy.bind(this);
        this.changeSchedule = this.changeSchedule.bind(this);
    }

    // Event Handlers
    goToCourse(e) {
        if (window.innerWidth < 768) {
            this.props.history.push(`/course/${this.props.data.id}`);
        }
    }

    goToBuy(e) {
        paymentService.reset();
        paymentService.item = { ...this.props.data.item, quantity: 1};
        paymentService.schedule = this.state.schedule;
        this.props.history.push("/buy");
    }

    changeSchedule(e) {
        this.setState({ schedule: this.props.data.schedules[e.target.value] });
    }

    // Component
    render() {
        const courseId = this.props.data.id;
        const title = this.props.data.item.title;
        const description = this.props.data.item.descriptionText;
        const category = this.props.data.subjectArea;
        const credits = this.props.data.credits;
        const courseNo = this.props.data.courseNo;
        const deliveryMethod = this.props.data.deliveryMethod;
        const schedules = this.props.data.schedules;
        const price = this.props.data.item.price;

        const shortDescription = description.length > 300 ? description.substring(0, 350).split(" ").slice(0, -1).join(" ") + "..." : description;

        const categoryTerm = bootstrapService.getCategoryTerm();
        const creditsTerm = bootstrapService.getCreditsTerm(credits);

        return (
            <li className="a4-plan-container -course-bundle" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                <div className="a4-plan-item">
                    <div className="a4-plan-section -webinar">
                        <h3 className="a4-plan-title" onClick={this.goToCourse}>{title}</h3>
                        <p className="a4-plan-description -course-bundle" onClick={this.goToCourse}>{shortDescription}</p>
                        <ul className="a4-plan-advantages -individual-course">
                            <li className="a4-plan-advantages-item -individual-course">
                                <span className="a4-plan-advantages-item-title"><span className="text-primary">{categoryTerm}:</span></span>
                                <span className="a4-plan-advantages-item-value">{category}</span>
                            </li>
                            <li className="a4-plan-advantages-item -individual-course">
                                <span className="a4-plan-advantages-item-title"><span className="text-primary">Delivery Method</span></span>
                                <span className="a4-plan-advantages-item-value">{deliveryMethod}</span>
                            </li>
                            <li className="a4-plan-advantages-item -individual-course">
                                <span className="a4-plan-advantages-item-title"><span className="text-primary">{creditsTerm}:</span></span>
                                <span className="a4-plan-advantages-item-value">{credits.toFixed(1)}</span>
                            </li>
                        </ul>
                        <ul className="a4-plan-advantages -webinar" onChange={this.changeSchedule}>
                            <li className="a4-plan-advantages-item -webinar-title">
                                <span className="a4-plan-advantages-item-title"><span className="text-primary">Dates & Times:</span></span>
                            </li>
                            {
                                schedules.slice(0, 6).map((schedule, scheduleIndex) => {
                                    return <li className="a4-plan-advantages-item -webinar" key={scheduleIndex}>
                                        <RadioButton value={scheduleIndex} index={scheduleIndex} course={courseId} />
                                        <span className="a4-plan-advantages-item-text">{new Date(schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}, {new Date(schedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - {new Date(schedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} ({scheduleService.getTimeZone()})</span>
                                    </li>
                                })
                            }
                        </ul>
                    </div> 
                    <div className="a4-plan-section -webinar">
                        <div className="a4-plan-price">
                            <b className="a4-plan-price-digits">
                                <span className="a4-plan-price-int">${String(price).split('.')[0]}</span>
                                <span className="a4-plan-price-float">{String(price).split('.')[1]}</span>
                            </b>
                        </div>
                        <div className="a4-plan-controls">
                            <BubbleButton onClick={this.goToBuy} className="a4-plan-buy-now -primary">Buy Now</BubbleButton>
                            <Link to={`/course/${courseId}`} className="a4-plan-learn-more -individual-course">About Course</Link>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default withRouter(Webinar);