import React from 'react'
import './Navbar.scss'
import { Link } from 'react-router-dom'

import BubbleButton from '../BubbleButton/BubbleButton'
import { ReactComponent as Apple } from '../../../assets/apple.svg'
// import { ReactComponent as Login } from './assets/login.svg'
// import { ReactComponent as Register } from './assets/register.svg'

import bootstrapService from '../../../services/BootstrapService'

class Navbar extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // Refs
        this.navbar = null;

        // State
        this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        this.state = {
            previousY: 0,
            isMobileNavbarOpened: false,
        }

        // This Binding
        this.scrollNavbar = this.scrollNavbar.bind(this);
        this.toggleMobileNavbar = this.toggleMobileNavbar.bind(this);
        this.clickHome = this.clickHome.bind(this);
        this.clickTryForFree = this.clickTryForFree.bind(this);
    }

    // Lifecycle
    componentDidMount() {
        this.setupScrollAnimation()
    }

    // Helpers
    setupScrollAnimation() {
        window.addEventListener("scroll", this.scrollNavbar);
    }

    showMobileNavbar() {
        this.setState({ isMobileNavbarOpened: true });
        document.body.classList.add("overflow-hidden");
        this.navbar.classList.add('-open');
    }

    hideMobilelNavbar() {
        this.setState({ isMobileNavbarOpened: false });
        document.body.classList.remove("overflow-hidden");
        this.navbar.classList.remove('-open');
    }

    toggleOverflowForModal() {
        const style = getComputedStyle(this.navbar)
        if (style.paddingRight === 0) {
            this.navbar.style.paddingRight = `${this.scrollBarWidth}px`;
        }
        else {
            this.navbar.style.paddingRight = 0;
        }
    }

    // Event Handlers
    scrollNavbar() {
        if (this.navbar) {
            const y = window.pageYOffset;

            const isPageStart = y < 50;
            if (y <= 10) {
                this.navbar.classList.remove("-fixed");
            }

            if (isPageStart || this.state.previousY > y) {
                this.navbar.classList.remove("-hide");
            } else {
                this.navbar.classList.add("-hide", "-fixed");
            }

            this.setState({ previousY: y });
        }
    }

    toggleMobileNavbar() {
        if (this.state.isMobileNavbarOpened) {
            this.hideMobilelNavbar();
        } else {
            this.showMobileNavbar();
        }
    }

    clickHome() {
        this.hideMobilelNavbar();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    clickTryForFree() {
        this.hideMobilelNavbar();

        if (this.props.clickTry) {
            this.props.clickTry();
        }
    }

    // Component
    render() {        
        const { backgroundColor, activeLink } = this.props;

        const navs = bootstrapService.data.landing.headers;
        const logo = bootstrapService.data.site.logoUrl;
        const pages = bootstrapService.data.landing.pages;

        const appStoreData = bootstrapService.data.landing.sections.find(s => s.name === "appStore");
        const appleUrl = appStoreData ? appStoreData.appleUrl : null;

        const tryForFreeData = bootstrapService.data.landing.sections.find(s => s.name === "tryForFree");
        const tryForFreeLabel = tryForFreeData && tryForFreeData.actionLabel ? tryForFreeData.actionLabel : 'Try For Free';

        const defaultPlanType = bootstrapService.defaultPlanType;
        const defaultStateName = bootstrapService.defaultStateName;

        const ceTerm = bootstrapService.getCETerm();

        return (
            <div className={"a4-navbar " + (backgroundColor ? backgroundColor : "")} ref={div => this.navbar = div}>
                <div className="a4-navbar-container">
                    <div className="a4-navbar-brand">
                        <Link to="/" onClick={this.clickHome}>
                            <div className="-logo">
                                <img src={logo} alt="" />
                            </div>
                        </Link>
                    </div>
                    <ul className="a4-navbar-menu">
                        {
                            navs.find(n => n === "approvals") &&
                            <li className={"a4-navbar-menu-item" + (activeLink === "approvals" ? " -active" : "")}>
                                <Link to="/approvals">Our Approvals</Link>
                            </li>
                        }
                        {
                            navs.find(n => n === "courses") &&
                            <li className={"a4-navbar-menu-item" + (activeLink === "courses" ? " -active" : "")}>
                                {
                                    bootstrapService.singleCourseId &&
                                    <Link to={`/course/${bootstrapService.singleCourseId}`}>The Course</Link>
                                }
                                {
                                    !bootstrapService.singleCourseId &&
                                    <Link to="/courses">Our Courses</Link>
                                }
                            </li>
                        }
                        {
                            navs.find(n => n === "pricing") &&
                            <li className={"a4-navbar-menu-item" + (activeLink === "pricing" ? " -active" : "")}>
                                <Link to={`/pricing/${defaultPlanType}`}>Pricing</Link>
                            </li>
                        }
                        {
                            navs.find(n => n === "requirements") &&
                            <li className={"a4-navbar-menu-item" + (activeLink === "requirements" ? " -active" : "")}>
                                <Link to={`/requirements/${defaultStateName}`}>{ceTerm} Requirements</Link>
                            </li>
                        }
                        {
                            navs.map(name => {
                                if (!['approvals','courses','pricing','requirements','try-for-free','no-login','no-register'].includes(name)) {
                                    const page = pages.find(p => p.name === name);
                                    return <li className={"a4-navbar-menu-item" + (activeLink == name ? " -active" : "")}><Link to={`/${name}`}>{page.header}</Link></li>;
                                }
                            })
                        }
                    </ul>
                    <div className="a4-navbar-navigation">
                        <div className="a4-navbar-account">
                            <ul className="a4-navbar-account-base-list">
                                {
                                    !navs.find(n => n === "no-login") &&
                                    <li className={"a4-navbar-account-base-list-item" + (activeLink === "login" ? " -active" : "")}>
                                        <Link to="/login">Login</Link>
                                    </li>
                                }
                                {
                                    !navs.find(n => n === "no-register") &&
                                    <li className={"a4-navbar-account-base-list-item" + (activeLink === "register" ? " -active" : "")}>
                                        <Link to="/register">Sign Up</Link>
                                    </li>
                                }
                            </ul>
                        </div>
                        {
                            navs.find(n => n === "try-for-free") &&
                            <>
                                {
                                    this.props.clickTry &&
                                    <div className="a4-navbar-try_free" onClick={this.clickTryForFree}>
                                        <BubbleButton className="-secondary">{tryForFreeLabel}</BubbleButton>
                                    </div>
                                }
                                {
                                    !this.props.clickTry &&
                                    <div className="a4-navbar-try_free">
                                        <Link to={{pathname: "/", state: {tryForFree: true}}} onClick={this.clickTryForFree}>
                                            <BubbleButton className="-secondary">{tryForFreeLabel}</BubbleButton>
                                        </Link>
                                    </div>
                                }
                            </>
                            
                        }
                        <div className="a4-navbar-control">
                            {
                                appleUrl &&
                                <div className="a4-navbar-control-item">
                                    <a href={appleUrl}>
                                        <BubbleButton className="a4-btn_store">
                                            <div className="a4-btn_store-icon">
                                                <svg className="a4-svgsprite -app-store">
                                                    <Apple />
                                                </svg>
                                            </div>
                                            <div className="a4-btn_store-text">Available in <b>App Store</b></div>
                                        </BubbleButton>
                                    </a>
                                </div>
                            }
                        </div>
                        <div className="a4-navbar-menu-toggle" onClick={this.toggleMobileNavbar}>
                            <button className="a4-btn a4-btn-burger" type="button" role="navigation" aria-label="Open navigation">
                                <span className="backdrop"></span>
                                <span className="burger"><span className="middle"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="a4-navbar-nav">
                    <div className="a4-navbar-nav-filler"></div>
                    <div className="a4-navbar-nav-content">
                        <div className="a4-navbar-nav-body">
                            <div className="a4-navbar-nav-body-inner">
                                <ul className="a4-navbar-nav-list">
                                    {
                                        navs.find(n => n === "approvals") &&
                                        <li className={"a4-navbar-nav-list-item"  + (activeLink === "approvals" ? " -active" : "")}>
                                            <Link to="/approvals" onClick={this.toggleMobileNavbar}>Our Approvals</Link>
                                        </li>
                                    }
                                    {
                                        navs.find(n => n === "courses") &&
                                        <li className={"a4-navbar-nav-list-item" + (activeLink === "courses" ? " -active" : "")}>
                                            {
                                                bootstrapService.singleCourseId &&
                                                <Link to={`/course/${bootstrapService.singleCourseId}`} onClick={this.toggleMobileNavbar}>The Course</Link>
                                            }
                                            {
                                                !bootstrapService.singleCourseId &&
                                                <Link to="/courses" onClick={this.toggleMobileNavbar}>Our Courses</Link>
                                            }
                                            
                                        </li>
                                    }
                                    {
                                        navs.find(n => n === "pricing") &&
                                        <li className={"a4-navbar-nav-list-item" + (activeLink === "pricing" ? " -active" : "")}>
                                            <Link to={`/pricing/${defaultPlanType}`} onClick={this.toggleMobileNavbar}>Pricing</Link>
                                        </li>
                                    }
                                    {
                                        navs.find(n => n === "requirements") &&
                                        <li className={"a4-navbar-nav-list-item" + (activeLink === "requirements" ? " -active" : "")}>
                                            <Link to={`/requirements/${defaultStateName}`} onClick={this.toggleMobileNavbar}>{ceTerm} Requirements</Link>
                                        </li>
                                    }
                                    {
                                        navs.map(name => {
                                            if (!['approvals','courses','pricing','requirements','try-for-free','no-login','no-register'].includes(name)) {
                                                const page = pages.find(p => p.name === name);
                                                return <li className={"a4-navbar-menu-item" + (activeLink == name ? " -active" : "")}><Link to={`/${name}`}>{page.header}</Link></li>;
                                            }
                                        })
                                    }
                                    {
                                        !navs.find(n => n === "no-login") &&
                                        <li className={"a4-navbar-nav-list-item" + (activeLink === "login" ? " -active" : "")} id="nav-login">
                                            <Link to="/login" onClick={this.toggleMobileNavbar}>Login</Link>
                                        </li>
                                    }
                                    {
                                        !navs.find(n => n === "no-register") &&
                                        <li className={"a4-navbar-nav-list-item" + (activeLink === "register" ? " -active" : "")} id="nav-reg">
                                            <Link to="/register" onClick={this.toggleMobileNavbar}>Sign Up</Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        {
                            navs.find(n => n === "try-for-free") &&
                            <div className="a4-navbar-nav-footer lg_hidden">
                                <div className="a4-navbar-nav-footer-inner">
                                    {
                                        this.props.clickTry &&
                                        <div onClick={this.clickTryForFree}>
                                            <button className="a4-btn -secondary">{tryForFreeLabel}</button>
                                        </div>
                                    }
                                    {
                                        !this.props.clickTry &&
                                        <Link to={{pathname: "/", state: {tryForFree: true}}} onClick={this.clickTryForFree}>
                                            <button className="a4-btn -secondary">{tryForFreeLabel}</button>
                                        </Link>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;