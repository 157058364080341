import React from 'react'
import { withRouter } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

import BubbleButton from '../../shared/BubbleButton/BubbleButton'
import RadioButton from '../../shared/RadioButton/RadioButton'
import { ReactComponent as CheckMark } from '../../../assets/check-mark.svg'

import bootstrapService from '../../../services/BootstrapService'
import paymentService from '../../../services/PaymentService'
import scheduleService from '../../../services/ScheduleService'

class CourseBundle extends React.Component {
    constructor(props) {
        super(props);

        // Constants
        const webinars = this.props.data.courseDigests.filter(c => c.schedules)
        this.webinar = webinars.length > 0 ? webinars[0] : null

        // State
        this.state = {
            schedule: this.webinar ? this.webinar.schedules[0] : null
        }

        // This Binding
        this.goToBuy = this.goToBuy.bind(this);
        this.changeSchedule = this.changeSchedule.bind(this);
    }

    // Event Handlers
    goToBuy(e) {
        paymentService.reset();
        paymentService.item = { ...this.props.data.item, quantity: 1 };
        paymentService.schedule = this.state.schedule;
        this.props.history.push("/buy");
    }

    changeSchedule(e) {
        this.setState({ schedule: this.webinar.schedules[e.target.value] });
    }

    // Component
    render() {
        const title = this.props.data.item.title;
        const description = this.props.data.item.descriptionText;
        const courses = this.props.data.courseDigests;
        const price = this.props.data.item.price;

        const shortDescription = description.length > 300 ? description.substring(0, 350).split(" ").slice(0, -1).join(" ") + "..." : description;

        return (
            <li className="a4-plan-container -course-bundle" data-aos={isMobileOnly ? undefined : "fade-up"} data-aos-duration='1400' data-aos-delay="0" data-aos-anchor-placement="top-bottom">
                <div className="a4-plan-item">
                    <div className="a4-plan-section">
                        <h3 className="a4-plan-title">{title}</h3>
                        <p className="a4-plan-description -course-bundle">{shortDescription}</p>
                        <ul className="a4-plan-advantages -course-bundle">
                            {
                                courses.map((course, courseIndex) => {
                                    return <li className="a4-plan-advantages-item -course-bundle" key={courseIndex}>
                                        <svg className="a4-svgsprite">
                                            <CheckMark stroke={bootstrapService.primaryColor} />
                                        </svg>
                                        <span className="a4-plan-advantages-item-text">{course.title}</span>
                                    </li>
                                })
                            }
                        </ul>
                        {
                            this.webinar &&
                            <ul className="a4-plan-advantages -webinar" onChange={this.changeSchedule}>
                            {
                                this.webinar.schedules.slice(0, 6).map((schedule, scheduleIndex) => {
                                    return <li className="a4-plan-advantages-item -webinar" key={scheduleIndex}>
                                        <RadioButton value={scheduleIndex} index={scheduleIndex} course={this.webinar.id} />
                                        <span className="a4-plan-advantages-item-text">{new Date(schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}, {new Date(schedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - {new Date(schedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} ({scheduleService.getTimeZone()})</span>
                                    </li>
                                })
                            }
                            </ul>
                        }
                    </div>
                    <div className="a4-plan-section">
                        <div className="a4-plan-price">
                            <b className="a4-plan-price-digits">
                                <span className="a4-plan-price-int">${String(price).split('.')[0]}</span>
                                <span className="a4-plan-price-float">{String(price).split('.')[1]}</span>
                            </b>
                        </div>
                        <div className="a4-plan-controls">
                            <BubbleButton onClick={this.goToBuy} className="a4-plan-buy-now -primary">Buy Now</BubbleButton>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default withRouter(CourseBundle);